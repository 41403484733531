import { createSelector } from '@reduxjs/toolkit';
import { StudioAdType } from '../../components/creative-studio/hooks/useStudioAdType';
import { SCENE_NAMES } from '../../components/creative-studio/scenes/studio-scene-helper';
import { getAd, updateAd } from '../../services/creative-service';
import { getSupplyRoute, updateSupplyRoute } from '../../services/supply-route-service';
import { LOGOUT } from '../action-types';
import { inStreamBaseUrl, inBreakBaseUrl } from '../../components/creative-studio/utils/url-helper';
import {
  ADD_ASSET_TO_THUMBNAIL_QUEUE,
  REMOVE_ASSET_FROM_THUMBNAIL_QUEUE,
  SET_AD_ERRORS,
  SET_ASSETS_ERRORS,
  SET_INITIAL_AD_FORMAT,
  SET_PREVIEW_ERRORS,
  TOGGLE_PREVIEW_OPEN,
  UPDATE_ACTIVE_SCENE,
  UPDATE_AD,
  UPDATE_AD_AND_PREVIEW,
  UPDATE_ASSETS,
  UPDATE_PREVIEW,
  UPDATE_VIDEO_THUMBNAIL_MAP,
  UPDATE_AD_FETCHED,
  SET_AD_LAYOUTS,
  UPDATE_DEBUG_ENCODE,
  TOGGLE_DEBUG_MODAL,
  TOGGLE_TRANSITION_SCENE_CARDS_HOVER,
  UPDATE_CONTENT_PREVIEW,
  SET_STUDIO_AD_TYPE_PARAMS,
  RESET_STUDIO_PROPS,
  TOGGLE_STANDALONE_PREVIEW,
  RESET_PREVIEW_PROPS,
} from './action-types';
import {
  getAssetsByAccountAdvertiserAndType,
  getAssetsByAccountAndType,
  getInBreakAsset,
  getInStreamAsset,
  updateInBreakAsset,
  updateInStreamAsset,
} from '../../services/asset-service';
import { DEFAULT_PREVIEW_CONTENT } from '../../services/layout-service';

export const PREVIEW_DEVICE = {
  tv: 'tv',
  tablet: 'tablet',
  phone: 'phone',
};

export const DEFAULT_AD_DURATION = 15;

const inBreakStudioAdTypeParams = {
  fetchAd: getAd,
  saveAd: updateAd,
  fetchAsset: getInBreakAsset,
  fetchAssets: getAssetsByAccountAdvertiserAndType,
  updateAsset: updateInBreakAsset,
  entityIdName: 'creativeId',
  entityIdNameDb: 'adId',
  entityName: 'ad',
  entityDisplayName: 'Creative',
  baseUrlFn: inBreakBaseUrl,
  transitionsEnabled: true,
  initialViewEnabled: true,
  liveDataEnabled: true,
  requiredFields: [],
};

const inStreamStudioAdTypeParams = {
  fetchAd: getSupplyRoute,
  saveAd: updateSupplyRoute,
  fetchAsset: getInStreamAsset,
  fetchAssets: getAssetsByAccountAndType,
  updateAsset: updateInStreamAsset,
  entityIdName: 'supplyRouteId',
  entityIdNameDb: 'supplyRouteId',
  entityName: 'supplyRoute',
  entityDisplayName: 'Supply Route',
  baseUrlFn: inStreamBaseUrl,
  transitionsEnabled: false,
  initialViewEnabled: false,
  liveDataEnabled: false,
  requiredFields: ['cpm', 'demand_source'],
};

export const previewInitialState = {
  loadingProgress: 0,
  standalone: false,
  open: false,
  loading: false,
  device: PREVIEW_DEVICE.tv,
  encodeUrl: null,
  mediaFileUrl: null,
  skinUrl: null,
  overlaySkinUrl: null,
  overlayUrl: null,
  overlayWidth: 0,
  overlayHeight: 0,
  noVideoDuration: 0,
  activeScene: {
    name: SCENE_NAMES.introTrans,
  },
  previewInventoryId: DEFAULT_PREVIEW_CONTENT._id,
  previewInventoryName: DEFAULT_PREVIEW_CONTENT.name,
  adDuration: DEFAULT_PREVIEW_CONTENT.duration,
  contentStreamId: '63d961507fc85541a3dcce61',
  timeOffsetMs: null,
};

export const studioInitialState = {
  activeSceneCard: {
    name: SCENE_NAMES.adContent,
  },
  adLayouts: [],
  ad: {
    _id: null,
    ad_server: 'Transmit',
    click_through: null,
    duration: null,
    name: '',
    url: null,
    bg_color: '000000',
    border_color: 'ffffff',
    format: null,
    layout: {},
    rotating: false,
    trackers: null,
    useMoatTrackers: false,
    video_id: null,
    video_url: null,
    overlay_id: null,
    overlay_url: null,
    preview_logo_id: null,
    preview_logo_url: null,
    skin_id: null,
    skin_url: null,
    previewInventoryId: '63e66d40d1440c2b7fdfedec',
    demand_source: 'transmit',
    cpm: 0,
    transitions: {
      introUrl: null,
      outroUrl: null,
      introAnimationUrl: null,
      transitionAnimation: null,
      isSceneCardHovered: false,
    },
    liveDataConfig: null,
  },
  adFetched: false,
  adErrors: {},
  assets: {
    currentAssetType: null,
    errors: {},
    refetchAssets: true,
  },
  debugEncode: {
    show: false,
  },
  contentPreview: {
    editMode: {
      enabled: false,
      element: null,
    },
  },
  videoAssetThumbnails: {
    needsThumbnailQueue: [],
    thumbnailUrls: {},
  },
  preview: previewInitialState,
  previewErrors: {},
  initialAdFormat: {
    show: true,
  },
  getStudioAdTypeParams: (studioAdType) => {
    return studioAdType === StudioAdType.IN_BREAK ? inBreakStudioAdTypeParams : inStreamStudioAdTypeParams;
  },
};

export const CreativeStudioReducer = (state = studioInitialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return studioInitialState;
    case RESET_STUDIO_PROPS:
      return studioInitialState;
    case UPDATE_ACTIVE_SCENE: {
      return {
        ...state,
        activeSceneCard: {
          ...state.activeSceneCard,
          name: action.name,
        },
      };
    }
    case UPDATE_AD: {
      const { type, ...rest } = action;
      return {
        ...state,
        ad: {
          ...state.ad,
          ...rest,
        },
      };
    }
    case UPDATE_ASSETS: {
      const { type, ...rest } = action;
      return {
        ...state,
        assets: {
          ...state.assets,
          ...rest,
        },
      };
    }

    case SET_ASSETS_ERRORS: {
      const { type, ...rest } = action;
      return {
        ...state,
        assets: {
          ...state.assets,
          errors: {
            ...rest,
          },
        },
      };
    }

    case TOGGLE_PREVIEW_OPEN: {
      return {
        ...state,
        preview: {
          ...state.preview,
          open: !state.preview.open,
        },
      };
    }
    case UPDATE_PREVIEW: {
      return {
        ...state,
        preview: {
          ...state.preview,
          ...action.preview,
        },
      };
    }
    case UPDATE_AD_AND_PREVIEW: {
      return {
        ...state,
        ad: {
          ...state.ad,
          ...action.ad,
        },
        preview: {
          ...state.preview,
          ...action.preview,
        },
      };
    }
    case SET_INITIAL_AD_FORMAT: {
      const { show, format } = action;
      return {
        ...state,
        initialAdFormat: {
          show,
        },
        ad: {
          ...state.ad,
          format,
        },
      };
    }
    case SET_AD_ERRORS: {
      const { type, ...rest } = action;
      return {
        ...state,
        adErrors: {
          ...rest,
        },
      };
    }
    case SET_STUDIO_AD_TYPE_PARAMS: {
      const { type, ...rest } = action;
      return {
        ...state,
        studioAdTypeParams: {
          ...rest,
        },
      };
    }
    case SET_PREVIEW_ERRORS: {
      const { type, ...rest } = action;
      return {
        ...state,
        previewErrors: {
          ...rest,
        },
      };
    }

    case ADD_ASSET_TO_THUMBNAIL_QUEUE: {
      const { asset } = action;
      const newQueue = state.videoAssetThumbnails.needsThumbnailQueue.concat(asset);

      return {
        ...state,
        videoAssetThumbnails: {
          ...state.videoAssetThumbnails,
          needsThumbnailQueue: newQueue,
        },
      };
    }

    case REMOVE_ASSET_FROM_THUMBNAIL_QUEUE: {
      const { assetId } = action;
      const newQueue = state.videoAssetThumbnails.needsThumbnailQueue.filter((asset) => asset._id !== assetId);

      return {
        ...state,
        videoAssetThumbnails: {
          ...state.videoAssetThumbnails,
          needsThumbnailQueue: newQueue,
        },
      };
    }

    case UPDATE_VIDEO_THUMBNAIL_MAP: {
      const { id, url } = action;
      return {
        ...state,
        videoAssetThumbnails: {
          ...state.videoAssetThumbnails,
          thumbnailUrls: {
            ...state.videoAssetThumbnails.thumbnailUrls,
            [id]: url,
          },
        },
      };
    }

    case UPDATE_AD_FETCHED: {
      const { adFetched } = action;
      return {
        ...state,
        adFetched,
      };
    }

    case SET_AD_LAYOUTS: {
      const { adLayouts } = action;
      return {
        ...state,
        adLayouts,
      };
    }

    case UPDATE_DEBUG_ENCODE: {
      const { data } = action;
      return {
        ...state,
        debugEncode: {
          ...state.debugEncode,
          ...data,
        },
      };
    }

    case TOGGLE_STANDALONE_PREVIEW: {
      return {
        ...state,
        preview: {
          ...state.preview,
          standalone: !state.preview.standalone,
        },
      };
    }

    case RESET_PREVIEW_PROPS: {
      return {
        ...state,
        preview: {
          ...previewInitialState,
        },
      };
    }

    case TOGGLE_DEBUG_MODAL: {
      return {
        ...state,
        debugEncode: {
          show: !state.debugEncode.show,
        },
      };
    }

    case TOGGLE_TRANSITION_SCENE_CARDS_HOVER: {
      const { isSceneCardHovered } = action;
      return {
        ...state,
        ad: {
          ...state.ad,
          transitions: {
            ...state.ad.transitions,
            isSceneCardHovered,
          },
        },
      };
    }

    case UPDATE_CONTENT_PREVIEW: {
      const { contentPreview } = action;
      return {
        ...state,
        contentPreview: {
          ...state.contentPreview,
          ...contentPreview,
        },
      };
    }

    default:
      return state;
  }
};

export const selectAd = (state) => state.studio.ad;
export const selectAdAdvertiserId = (state) => state.studio.ad.advertiserId;
export const selectAdBgColor = (state) => state.studio.ad.bg_color;
export const selectAdBorderColor = (state) => state.studio.ad.border_color;
export const selectAdLayout = (state) => state.studio.ad.layout;
export const selectAdPreviewInventoryId = (state) => state.studio.ad.previewInventoryId;
export const selectAdTransitions = (state) => state.studio.ad.transitions;
export const selectAdFormat = (state) => state.studio.ad.format;
export const selectAdVideoId = (state) => state.studio.ad.video_id;
export const selectAdOverlayId = (state) => state.studio.ad.overlay_id;
export const selectAdServer = (state) => state.studio.ad.adServer;
export const selectAdName = (state) => state.studio.ad.name;
export const selectAdDuration = (state) => state.studio.ad.duration;
export const selectAdClickThrough = (state) => state.studio.ad.click_through;
export const selectAdUrl = (state) => state.studio.ad.url;
export const selectAdVideoUrl = (state) => state.studio.ad.video_url;
export const selectAdSkinUrl = (state) => state.studio.ad.skin_url;
export const selectAdOverlaySkinUrl = (state) => state.studio.ad.overlaySkinUrl;
export const selectAdOverlayUrl = (state) => state.studio.ad.overlay_url;
export const selectAdCpm = (state) => state.studio.ad.cpm;
export const selectAdDemandSource = (state) => state.studio.ad.demand_source;
export const selectAdLiveDataConfig = (state) => state.studio.ad.liveDataConfig;

export const selectGetStudioAdTypeParams = (state) => state.studio.getStudioAdTypeParams;
export const selectShowInitAdFormat = (state) => state.studio.initialAdFormat.show;
export const selectAdLayouts = (state) => state.studio.adLayouts;
export const selectAdFetched = (state) => state.studio.adFetched;
export const selectActiveSceneCard = (state) => state.studio.activeSceneCard;
export const selectPreviewErrors = (state) => state.studio.previewErrors;
export const selectAdErrors = (state) => state.studio.adErrors;

export const selectPreview = (state) => state.studio.preview;
export const selectPreviewInventoryName = (state) => state.studio.preview.previewInventoryName;
export const selectPreviewMediaFileUrl = (state) => state.studio.preview.mediaFileUrl;
export const selectPreviewSkinUrl = (state) => state.studio.preview.skinUrl;
export const selectPreviewOverlaySkinUrl = (state) => state.studio.preview.overlaySkinUrl;
export const selectPreviewOverlayUrl = (state) => state.studio.preview.overlayUrl;
export const selectPreviewOverlayImageWidth = (state) => state.studio.preview.overlayWidth;
export const selectPreviewOverlayImageHeight = (state) => state.studio.preview.overlayHeight;

export const selectStudioContentPreviewProps = createSelector(
  [
    selectAdAdvertiserId,
    selectAdBgColor,
    selectAdBorderColor,
    selectAdLayout,
    selectAdPreviewInventoryId,
    selectAdTransitions,
    selectAdFormat,
    selectActiveSceneCard,
    selectPreviewErrors,
    selectGetStudioAdTypeParams,
    selectPreviewMediaFileUrl,
    selectPreviewSkinUrl,
    selectPreviewOverlaySkinUrl,
    selectPreviewOverlayUrl,
    selectPreviewOverlayImageWidth,
    selectPreviewOverlayImageHeight,
    selectAdLiveDataConfig,
  ],
  (
    advertiserId,
    bgColor,
    borderColor,
    layout,
    previewInventoryId,
    transitions,
    format,
    activeSceneCard,
    previewErrors,
    getStudioAdTypeParams,
    mediaFileUrl,
    previewSkinUrl,
    previewOverlaySkinUrl,
    previewOverlayUrl,
    previewOverlayImageWidth,
    previewOverlayImageHeight,
    adLiveDataConfig
  ) => ({
    advertiserIdFromAd: advertiserId,
    bg_color: bgColor,
    border_color: borderColor,
    layout,
    previewInventoryId,
    transitions,
    format,
    activeSceneCard,
    previewErrors,
    getStudioAdTypeParams,
    mediaFileUrl,
    previewSkinUrl,
    previewOverlaySkinUrl,
    previewOverlayUrl,
    previewOverlayImageWidth,
    previewOverlayImageHeight,
    adLiveDataConfig,
  })
);

export const selectStudioSceneProps = createSelector(
  [
    selectActiveSceneCard,
    selectGetStudioAdTypeParams,
    selectAdTransitions,
    selectAdVideoId,
    selectAdOverlayId,
    selectAdServer,
    selectAdAdvertiserId,
  ],
  (activeSceneCard, getStudioAdTypeParams, adTransitions, adVideoId, adOverlayId, adServer, adAdvertiserId) => ({
    activeSceneCard,
    getStudioAdTypeParams,
    transitions: adTransitions,
    video_id: adVideoId,
    overlay_id: adOverlayId,
    adServer,
    adAdvertiserId,
  })
);

export const selectStudioSceneCardProps = createSelector(
  [selectAdTransitions, selectAdFormat, selectAdLayout, selectGetStudioAdTypeParams],
  (adTransitions, adFormat, adLayout, getStudioAdTypeParams) => {
    return {
      transitions: adTransitions,
      format: adFormat,
      layout: adLayout,
      getStudioAdTypeParams,
    };
  }
);

export const selectAdDetailsProps = createSelector(
  [
    selectAdFormat,
    selectAdServer,
    selectAdName,
    selectAdDuration,
    selectAdClickThrough,
    selectAdUrl,
    selectAdVideoUrl,
    selectAdSkinUrl,
    selectAdOverlaySkinUrl,
    selectAdOverlayUrl,
    selectAdCpm,
    selectAdDemandSource,
    selectAdErrors,
    selectPreviewErrors,
    selectAdFetched,
  ],
  (
    adFormat,
    adServer,
    adName,
    adDuration,
    adClickThrough,
    adUrl,
    adVideoUrl,
    adSkinUrl,
    adOverlaySkinUrl,
    adOverlayUrl,
    adCpm,
    adDemandSource,
    adErrors,
    previewErrors,
    adFetched
  ) => {
    return {
      format: adFormat,
      name: adName,
      duration: adDuration,
      ad_server: adServer,
      click_through: adClickThrough,
      url: adUrl,
      video_url: adVideoUrl,
      skin_url: adSkinUrl,
      overlaySkinUrl: adOverlaySkinUrl,
      overlay_url: adOverlayUrl,
      cpm: adCpm,
      demand_source: adDemandSource,
      adErrors,
      previewErrors,
      adFetched,
    };
  }
);
