export const PREVIEW_DEVICE = {
  tv: 'tv',
  laptop: 'laptop',
  phone: 'phone',
};

export const ENCODE_STATUS = Object.freeze({
  complete: 'complete',
  encoding: 'encoding',
  error: 'error',
  none: 'none',
  submitted: 'submitted',
});

export const getEncodeProgress = ({ progress, currentInterval, benchmark }) => {
  let currentProgress = progress;
  if (currentInterval > 120) {
    return { currentProgress: null, benchmark, currentInterval };
  }
  if (!progress) {
    currentInterval += 1;
    currentProgress = Math.min(29, currentInterval);
  }
  if (progress === 30) {
    if (!benchmark) {
      benchmark = 30;
      currentInterval = 0;
      currentProgress = Math.min(59, currentInterval + progress);
    } else {
      currentInterval += 1;
      currentProgress = Math.min(59, currentInterval + progress);
    }
  }
  if (progress === 60) {
    if (benchmark === 30) {
      benchmark = 60;
      currentInterval = 0;
      currentProgress = Math.min(99, currentInterval + progress);
    } else {
      currentInterval += 1;
      currentProgress = Math.min(99, currentInterval + progress);
    }
  }
  return { currentProgress, benchmark, currentInterval };
};
