export default {
  backgroundColor: '#fff',
  topNav: '#F8F9FA',

  errorRed: '#f44336',
  formErrorRed: '#d32f2f',

  chartFillDark: '#90caf9',
  chartFillLight: '#bbdefb',

  chartFill: '#CBDDEA',
  chartInfo: '#f0f5f9',
  chartLine: 'steelblue',

  paceBorderColor: '#ff7961',
  spendBorderColor: '#4caf50',

  //grey
  tableGrey: 'rgba(224, 224, 224, 1)',
  textGrey: '#7A7A7A',
  grey50: '#fafafa',
  grey100: '#f5f5f5',
  grey200: '#eeeeee',
  grey300: '#e0e0e0',
  grey400: '#bdbdbd',
  grey500: '#7d7d7d',
  subHeaderBGGrey: '#F8F9FA',
  lightGrey: '#DEE0E4',
  placeholderGrey: 'rgba(30, 30, 30, 0.63)',
  searchGrey: '#00000040',
  transparentGrey: 'rgba(190, 190, 190, 0.5)',
  avatarGrey: '#8092A4',
  menuItemGrey: 'rgba(0, 0, 0, 0.04)',

  //purples
  purple0: '#F1EBFE',
  purple50: '#efe6ff',
  purple100: '#deccff',
  purple200: '#ceb3ff',
  purple300: '#bd99ff',
  purple400: '#ad80ff',
  purple500: '#9c66ff',
  purple600: '#8c4dff',
  purple700: '#7b33ff',
  purple800: '#6b1aff',
  purple900: '#5a00ff',
  purple1000: '#5100e6',
  purple2000: '#4800cc',
  purple3000: '#3f00b3',
  purple4000: '#360099',
  purple5000: '#2d0080',
  purpleTransparent900: '#5a00ff33',
  menuItemSelectedPurple: 'rgba(90, 36, 251, 0.08)',
  menuItemSelectedHoverPurple: 'rgba(90, 36, 251, 0.12)',
  chipPurple: '#7F40FC',
  chipPurpleFocused: 'rgba(64, 28, 137, 1)',

  //blue
  blue300: '#64b5f6',
  blue700: '#1976d2',
  blue900: '#0D47A1',

  //oranges
  orange50: '#FFEFE6',
  orange100: '#FFDECC',
  orange200: '#FFCEB3',
  orange300: '#FFBD99',
  orange500: '#FF9C66',
  orange700: '#FF7B33',
  orange900: '#FF5A00',
  orange1000: '#E65100',
  orange5000: '#802D00',
  deepOrange700: '#f4511e',

  //red
  red50: '#ffebee',
  red100: '#ffcdd2',
  red300: '#e57373',
  red400: '#ef5350',
  red500: '#f44336',

  //green
  green50: '#e8f5e9',
  green100: '#c8e6c9',
  green200: '#a5d6a7',
  green400: '#66bb6a',
  green500: '#4caf50',
  green600: '#43a047',
  green900: '#1b5e20',

  //amber
  amber50: '#fff8e1',

  //white
  white: '#ffffff',
  adWhite50: 'rgba(255,255,255,.5)',

  //black
  black: '#000000',
  lightBlack: '#6d6d6d',

  composerLinkBlack: '#21313c',
  composerLinkHoverPurple: '#7B50FC',

  composerBlack: '#001e2b',
  composerTextBlack: '#3d4f58',

  composerMarketingPurple: '#5A24FB',

  composerPurple: '#7B50FC',
  composerLightPurple: '#F2EDFF',
  composerVeryLightPurple: '#F3EFFF',

  composerBorderGrey: '#E5E5E5',
  composerBackgroundGrey: '#F9F9F9',

  composerButtonPurpleHover: '#9877fd',
  composerButtonGrey: '#F5F5F5',
  composerButtonGreyHover: '#e1e1e1',

  composerGreyDefaultBackground: '#EFF1F4',

  //yellow
  adYellow10: 'rgba(242,193,79,.10)',
  adYellow25: 'rgba(242,193,79,.25)',
  adYellow50: 'rgba(242,193,79,.5)',
  adYellow100: 'rgb(242,193,79)',

  yellow: '#ffff00',

  amber400: '#FFCA28',
  lockGold: '#cdae07',
};
