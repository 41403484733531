export const adFormatLabels = {
  pip: 'Picture-in-Picture',
  squeezeBack: 'Squeeze Back',
  overlay: 'Overlay',
  interstitial: 'Full Screen',
};

export const formatRequestDataHeaders = (h) => {
  return Object.keys(h).reduce((acc, cur) => {
    if (acc) {
      acc += ', ';
    }
    acc += `${cur}: ${h[cur]}`;
    return acc;
  }, '');
};

const restrictedList = {
  accept: true,
  'accept-language': true,
  'accept-charset': true,
  'accept-encoding': true,
  'access-control-request-headers': true,
  'access-control-request-method': true,
  connection: true,
  'content-length': true,
  cookie: true,
  cookie2: true,
  date: true,
  dnt: true,
  expect: true,
  'feature-policy': true,
  host: true,
  'keep-alive': true,
  origin: true,
  'proxy-': 'proxy-',
  'sec-': 'sec-',
  te: true,
  trailer: true,
  'transfer-encoding': true,
  upgrade: true,
  via: true,
  'user-agent': true,
  referer: true,
  'x-amzn-trace-id': true,
  'x-amz-cf-id': true,
  'x-forwarded-for': true,
  'x-forwarded-port': true,
  'x-forwarded-proto': true,
  'x-playback-session-id': true,
  priority: true,
};

export const filterRestrictedHeaders = (headers) => {
  return Object.keys(headers)
    .filter((h) => {
      const lowerH = h.toLowerCase();
      const [leader] = lowerH.split('-');
      if (leader) {
        const start = `${leader}-`;
        if (restrictedList[start] && restrictedList[start] !== true) {
          return !h.startsWith(restrictedList[start]);
        }
      }
      return !restrictedList[lowerH];
    })
    .reduce((obj, key) => {
      obj[key] = headers[key];
      return obj;
    }, {});
};

export const accountPlaybackUrl = (accountSubdomain, accountId) => {
  const prefix = accountSubdomain || accountId;
  try {
    if (COMPOSER_INTEGRATION_PLAYBACK) {
      return COMPOSER_INTEGRATION_PLAYBACK.replace('integration', `${prefix}.integration`);
    }
  } catch (error) {
    //
  }
  return COMPOSER_GLOBAL_PLAYBACK.replace('global', `${prefix}.global`);
};
